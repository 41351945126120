/* Table of contents:

1. Globals
2. CommonElements
  2.1 Header
  2.2 focalPoint
  2.3 userControls
  2.4 authenitcation
  2.5 userPreferences
3. Tester
  3.1 answerGrid
  3.2 answerCardNumber
  3.3 playAudioIcon
  3.3 dragAndDrop

  c2e1b5
*/

/* 1. Globals */

:root {
  --main-colour: #16171a;
  --mint: #bedab2;
  --yellow: #f9eac2;
  --orange: #ffd898;
  --pink: #fee7e6;
  --red: #e7625f;
  --main-colour-10-opacity: rgba(22, 23, 26, 0.1);
  --main-white-colour: rgb(253, 255, 250); /*rgb(248, 252, 239);*/
  --amplify-components-checkbox-icon-background-color: #16171a !important;
}

html * {
  font-family: 'Poppins', sans-serif;
}

header {
  font-family: 'Poppins', sans-serif;
}

.fullPageLoader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 50vw;
  background-image: url('hexagons_cropped.svg');
}

body {
  scroll-behavior: smooth;
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.mainButton {
  min-width: 10rem !important;
  font-size: 1.2rem !important;
  color: var(--main-white-colour) !important;
  background-color: var(--main-colour) !important;
  text-transform: lowercase !important;
}

.mainButton:hover {
  background-color: var(--main-colour-10-opacity) !important;
  border-color: var(--main-colour) !important;
  color: var(--main-colour) !important;
}

.disabledMainButton {
  min-width: 10rem !important;
  font-size: 1.2rem !important;
  min-width: '10rem' !important;
  text-transform: lowercase !important;
  background-color: var(--main-colour-10-opacity) !important;
  border-color: var(--main-colour) !important;
  color: var(--main-colour) !important;
}

.mainButtonWhite {
  font-size: 1.2rem !important;
  background: var(--main-white-colour) !important;
  color: var(--main-colour) !important;
  text-transform: lowercase !important;
  border: 1px solid var(--main-colour-10-opacity) !important;
}

.mainButtonWhite:hover {
  color: var(--main-colour) !important;
  background-color: var(--main-colour-10-opacity) !important;
}

/* 
.disabledMainButton:hover{
  color: var(--main-white-colour)!important;
  background: var(--main-colour) !important;
  text-transform: lowercase !important;
} */

/* 
.MuiOutlinedInput-root{
  box-shadow: inset 0 10px 0 10px white
} */

/* 2. Common Elements */
/* 2.1 */
header {
  /* background: rgb(231, 230, 227); */
  /*background-color: var(--main-white-colour) !important;*/
  /* background-color: var(--mint) !important; */
  background: linear-gradient(227.28deg, #5ba8dc 11.88%, #bedab2 90.44%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--main-colour) !important;
  position: fixed !important;
  top: 0 !important;
  z-index: 999 !important;
  /* background: linear-gradient(90deg, rgba(231, 230, 227,1) 0%, rgba(224, 223, 220,1) 50%, rgba(231, 230, 227,1) 100%); */
}

/* @media only screen and (max-width: 600px) {
  header {
    background-color: #f9eac2 !important;
  }
} */

/* @media only screen and (max-width: 800px) {
  header {
    background-color: #ffd898 !important;
  }
} */

/* @media only screen and (max-width: 1000px) {
  header {
    background-color: #fee7e6 !important;
  }
} */

.header-hexapla {
  font-weight: 700 !important;
  font-family: 'Poppins', sans-serif;
  font-size: 1.8rem !important;
  color: var(--main-colour);
}

.header-icon {
  padding-right: 0 !important;
  margin: 0 !important;
}

.headerActionButton {
  margin-right: 0;
  width: fit-content;
  margin-left: auto;
  display: inline-block;
}

/* .mainHeader{
  padding-left: 2em;
} */

/* 2.2 */
.focalPoint {
  text-align: center;
  margin-top: 1.5rem;
}

/* 
.membershipPage{
  text-align: center;
  margin: auto;
  justify-content: center;
  right: unset;
  left: unset;
  top: unset;
  bottom: unset;
} */

.membershipPageContent {
  position: 'absolute';
  top: '50%';
  transform: 'translate(-50%, -50%)';
  left: '50%';
  border: '2px solid #000';
}

/* 2.3 */
.userControls {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-align: center;
}

/* 2.4 */
.amplify-button[data-variation='primary'] {
  /* border-width: var(--amplify-components-button-primary-border-width); */
  background-color: var(--main-colour) !important;
  /* border-color: var(--amplify-components-button-primary-border-color); */
  /* color: var(--amplify-components-button-primary-color); */
}

.amplify-button[data-variation='primary'] {
  /* border-width: var(--amplify-components-button-primary-border-width); */
  background-color: var(--main-colour) !important;
  /* border-color: var(--amplify-components-button-primary-border-color); */
  /* color: var(--amplify-components-button-primary-color); */
}

.amplify-button[data-loading='true'] {
  /* border-width: var(--amplify-components-button-primary-border-width); */
  background-color: rgb(22, 23, 26, 0.1) !important;
  /* border-color: var(--amplify-components-button-primary-border-color); */
  /* color: var(--amplify-components-button-primary-color); */
}

.amplify-button[data-variation='primary']:hover {
  /* border-width: var(--amplify-components-button-primary-border-width); */
  /* color: var(--main-colour) !important; */
  background-color: rgb(22, 23, 26, 0.1) !important;
  /* border-color: var(--amplify-components-button-primary-border-color); */
  /* color: var(--amplify-components-button-primary-color); */
}

.amplify-button[data-variation='link'] {
  color: var(--main-colour) !important;
}

.amplify-button:hover {
  background-color: rgb(22, 23, 26, 0.1) !important;
  border-color: var(--main-colour) !important;
}

.amplify-tabs-item[data-state='active'] {
  border-color: var(--main-colour) !important;
  color: var(--main-colour) !important;
}

.amplify-tabs-item:hover {
  color: var(--main-colour) !important;
}

/* 2.5 */

.userPreferencesFormContainer {
  padding: 2rem;
}

.userPreferencesForm {
  margin: 2rem;
}
/* Tester */

/* answerGrid */

.answerGrid {
  justify-content: center;
}

Button.answerGridCard {
  background-color: var(--main-white-colour);
  font-size: 1.25rem;
}

Button.answerGridCard:hover {
  background: rgb(231, 230, 227);
}

.questionWord {
  font-size: 2rem !important;
  height: 4rem;
}

/* answerCardNumber */

.answerCardNumber {
  position: absolute;
  color: darkgrey;
  top: 0;
  right: 0;
  padding-right: 0.2em;
  padding-top: 0.2em;
}

/* typingInputCard */

.typingInputContainer {
  position: relative;
}

.typingInputArea {
  margin: 2rem 0 0 0 !important;
  position: relative;
  padding-right: 0 !important;
  align-content: center;
  transition-duration: 0s !important;
}

/* .typingInputArea::after{
  content: "asdas";
  background: red;
  width: 12px !important;
  z-index: 10
} */

.typingInputArea:focus-visible {
  border-color: var(--main-white-colour) !important;
}

.typingInputArea > div > input {
  border-radius: 0 inherit inherit 0 !important;
  border-left: 1px solid !important;
}

/* 
.typingInputArea > div > input:hover{
  border-color: var(--main-white-colour)!important;
} */

.typingInputArea::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 1.8rem solid grey;
  /* border-radius: 5px 4px 5px 5px; */
  opacity: 0.5;
  z-index: 2;
  position: absolute;
  border-left: 1.8rem solid transparent;
}
.currentDateIndicator::before {
  content: '';
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-top: 2rem solid green;
  opacity: 0.5;
  z-index: 1;
  position: absolute;
  border-left: 2rem solid transparent;
}

.typingInputToolTip {
  position: relative;
  display: inline-block;
  margin: 1.4rem 0 0 -1.4rem !important;
  z-index: 5;
}

.typingInputToolTipIcon {
  color: var(--main-colour) !important;
  font-size: 1.05rem !important;
}

.noHoverEffect:hover {
  background: unset !important;
  background-color: transparent !important;
  align-items: unset !important;
  -webkit-align-items: unset !important;
}

.userSubmitButton {
  border-right: 1px solid var(--main-colour);
  border-radius: 0 !important;
}

.virtualKeyBoard {
  margin: 2rem 0 0 0 !important;
}

/* playAudioIcon */

.playAudioIconContainer {
  height: 100%;
}

.playAudioIcon {
  height: 4rem !important;
  width: 100% !important;
}

/* .answerCardNumber:after{
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  left: 0;
  background: var(--main-colour);
  border: var(--main-colour);
  border-width: 2px;
} */

/* draganddrop */

/* .sortableContext-horizontal{
  display: flex;
} */

.dragDropZone {
  /* background: lightblue !important;
  min-height: 200px;
  min-width: 200px; */
}

.draggableItem {
  width: '100%';
  height: 50;
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  margin: '10px 0';
}
.draggableItem:hover {
  background-color: greenyellow !important;
}

/* 
.MuiSlider-root, .MuiSlider-thumb, .MuiSlider-track, .MuiSlider-active {
  color: var(--main-colour) !important;
}

.MuiSlider-thumb, .MuiSlider-active{
  box-shadow: 0px 0px 0px 8px var(--main-colour) 0.16;
}
 */

.mainButtonContrast {
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: var(--amplify-components-button-border-radius) !important;
  box-sizing: border-box !important;
  font-weight: var(--amplify-components-button-font-weight) !important;
  transition: all var(--amplify-components-button-transition-duration);
  border-width: var(--amplify-components-button-border-width) !important;
  border-style: var(--amplify-components-button-border-style) !important;
  border-color: var(--main-colour) !important;
  border-radius: var(--amplify-components-button-border-radius) !important;
  /* background: var(--main-colour) !important; */
  color: var(--amplify-components-button-color) !important;
  font-size: var(--amplify-components-button-font-size) !important;
  line-height: var(--amplify-components-button-line-height) !important;
  padding-block-start: var(
    --amplify-components-button-padding-block-start
  ) !important;
  padding-block-end: var(
    --amplify-components-button-padding-block-end
  ) !important;
  padding-inline-start: var(
    --amplify-components-button-padding-inline-start
  ) !important;
  padding-inline-end: var(
    --amplify-components-button-padding-inline-end
  ) !important;
  -webkit-user-select: none;
  user-select: none !important;
  text-transform: lowercase !important;
  width: 'fit-content' !important;
  font-size: 1.2rem !important;
  cursor: pointer !important;
}

.mainButtonContrast:hover {
  background-color: rgb(22, 23, 26, 0.2) !important;
  color: var(--main-white-colour) !important;
}

.ctaButtons {
  margin-top: 5rem;
  /* display: flex  !important;
   justify-content: space-evenly !important; */
}

.ctaButtonMargin {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
  margin-bottom: 2.5rem !important;
}

/* .homePage{
   padding: 15rem !important;
 } */

/* .learnMoreContainer{
    margin-top: 35rem;
 } */

.welcomeSplash {
  height: 100vh;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* margin-bottom: 5rem !important; */
  /* border-bottom: var(--main-colour) solid 2px; */
}

.learnMoreContainer {
  height: 100vh;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* margin-bottom: 5rem !important; */
}

.treeContainer {
}
.tree {
}

.treeText {
}

.headerIconContainer {
  height: 3rem !important;
  width: 3rem !important;
  margin: auto;
}

.headerIcon {
  height: 100% !important;
  font-size: 3rem !important;
  color: var(--main-colour) !important;
}

.pricingCardOffer::before {
  content: '';
  right: 0;
  width: 0;
  height: 0;
  border-top: 4rem solid grey;
  /* border-radius: 5px 4px 5px 5px; */
  opacity: 0.5;
  z-index: 2;
  position: absolute;
  border-left: 4rem solid grey;
}

::-webkit-scrollbar {
  display: none;
}

@keyframes pop {
  50% {
    transform: scale(1.1);
  }
}

.recordingUser {
  text-align: center;
}

.recordingArea {
  border: 3px solid #16171a;
  border-radius: 25px;
  display: inline-flex !important;
  max-width: fit-content;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.recordingIndicatorProgress {
  display: inline-flex !important;
  align-items: center;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

#dots {
  align-items: center;
  width: 100% !important;
  height: 100% !important;
}

#dots #dot1 {
  animation: load 1s infinite;
}

#dots #dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots #dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.MuiSlider-valueLabelOpen .MuiSlider-valueLabel {
  display: none;
}
